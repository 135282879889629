import React, {FC} from 'react'
import cls from "../builder_carousel.module.scss";

interface IProps {
    card: Record<string, string>,
    eventListeners: {
        contentStyle: Record<string, string>,
        buttonStyle: Record<string, string>,
        onMouseOver: (e: React.MouseEvent<HTMLDivElement>) => void,
        onMouseOut: (e: React.MouseEvent<HTMLDivElement>) => void,
        onMouseOverButton: (e: React.MouseEvent<HTMLButtonElement>, type: string) => void,
        onMouseOutButton: (e: React.MouseEvent<HTMLButtonElement>, type: string) => void
    }
}

const TestimonialCard: FC<IProps> = ({card, eventListeners}) => {
    const {
        title = "",
        description = "",
        buttonContent = "",
        linkValue = "",
        targeting = "",
    } = card

    const {
        contentStyle,
        buttonStyle,
        onMouseOver,
        onMouseOut,
        onMouseOverButton,
        onMouseOutButton
    } = eventListeners

    const handleButton = {
        true: <button
            className={cls["carousel_card_button"]}
            style={buttonStyle}
            onMouseOver={(e) => onMouseOverButton(e, "button")}
            onMouseOut={(e) => onMouseOutButton(e, "button")}
        >
            {buttonContent}
        </button>,
        false: <></>,
    }

    return <div className={cls["carousel_card_wrapper"]}>
        <a
            href={linkValue}
            rel={"noreferrer"}
            target={targeting}
        >
            <div className={cls["carousel_card"]}>
                <div className={`${cls["carousel_card_content"]} ${cls.testimonials}`}>
                    <div
                        className={cls["carousel_card_title"]}
                        style={contentStyle}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    >
                        {title}
                    </div>
                    <div
                        className={cls["carousel_card_description"]}
                        style={contentStyle}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    >
                        {description}
                    </div>
                    {handleButton[`${!!buttonContent}`]}
                </div>
            </div>
        </a>
    </div>
}

export default TestimonialCard