import {RefObject, useEffect, useState} from "react";

export default function useIsInView(ref: RefObject<HTMLDivElement>) {
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const rect = ref.current?.getBoundingClientRect();
            if (rect) {
                const elementTop = rect.top + window.scrollY; // Add scrollY for accurate position
                const elementBottom = elementTop + rect.height;
                const viewportTop = window.scrollY;
                const viewportBottom = viewportTop + window.innerHeight;


                setIsInView(elementTop >= viewportTop && elementBottom <= viewportBottom);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [ref]);

    return isInView || false;
}