import {FC, Fragment, ReactNode} from 'react';
import {ReCaptchaProvider} from 'next-recaptcha-v3';

interface IProps {
    children: ReactNode;
    recaptchaActive: string;
    recaptchaKey: string
}

const ReCaptchaWrapper: FC<IProps> = ({children, recaptchaActive, recaptchaKey}) => {
    if (!+recaptchaActive) return <Fragment>{children}</Fragment>;
    return <ReCaptchaProvider useEnterprise={true} reCaptchaKey={recaptchaKey}>
        {children}
    </ReCaptchaProvider>
}
export default ReCaptchaWrapper;