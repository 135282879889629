import React, {FC, useEffect, useRef, useState} from "react";
import {IProduct, TranslationsType} from "../../../types/homePageTypes";
import ProductCard from "../../ForCWV/Products/ProductCard";
import cls from "../../ForCWV/Products/products.module.scss";
import BlockHeader from "../../ForCWV/Products/BlockHeader";

interface IProps {
    item: {
        productListComponentInfo: {
            products: Array<IProduct>,
            block_title: string
        },
        showProductListCls: string,
        convertedSettings: {
            animationAos: {
                "data-aos": string
            },
            styles: any;
            parentStyles: any
        }
    }
    dbName: string;
    translationsData: TranslationsType;
    backOrderValue: string;
    selectedCurrItems: {
        selectedCurrCode: string;
        selectedCurrRate: string;
        selectedCurrSymbol: string
    };
    selectedLocale: string;
    isMobile: boolean
}

const Index: FC<IProps> = (
    {
        item: {
            productListComponentInfo: {
                products = [],
                block_title = ""
            } = {},
            showProductListCls = "",
            convertedSettings: {
                animationAos: {"data-aos": dataAos = "none"} = {},
            } = {}
        } = {},
        isMobile,
        ...pageProps
    }
) => {
    const ref = useRef<HTMLDivElement>(null)
    const [originalProducts, setOriginalProducts] = useState<boolean>(false);

    const handleBlockTitle = {
        true: {
            true: <div>
                <BlockHeader title={block_title}/>
            </div>,
            false: null
        },
        false: {
            true: null,
            false: null
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (ref && ref.current) {
                setOriginalProducts(window.scrollY >= ref.current?.offsetTop - 2)
            }
        }
        if (!originalProducts && isMobile) {
            window.addEventListener("scroll", handleScroll)
        } else {
            window.removeEventListener("scroll", handleScroll)
        }
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return <div data-aos={dataAos}>
        {handleBlockTitle[`${!!products.length}`][`${!!block_title}`]}
        <div className={cls["block-products__body"]}>
            <div className={cls["block-products__list"]}>
                {(isMobile && !originalProducts ? products.filter((_, indexFil) => indexFil < 4) : products).map((product: IProduct, index: number) => {
                    return (
                        <div key={product.id} style={{minHeight: 200}} className={cls["product-card-parent"]}>
                            <div ref={!originalProducts ? ref : null} className={cls["block-products__list-item"]}>
                                <ProductCard
                                    product={product}
                                    priority={index < 2}
                                    {...pageProps}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
}

export default Index;